import Vue from 'vue'
import dateMixin from '~/mixins/dates.js'
import popoverComponent from '@showjoe/ffui/src/components/popover.vue'
import tooltipComponent from '@showjoe/ffui/src/components/tooltip.vue'
Vue.directive('mask', {
  inserted: function (el, binding) {
    var mask = binding.value
    new Inputmask(mask).mask(el);
  }
})
Vue.directive('clear', {
  unbind: function (el, binding, vnode) {
    console.log('v-clear')
    // Find the elements record and clear it
    var record = binding && binding.value ? binding.value : false
    if (vnode.data.model && vnode.data.model.expression) record = vnode.data.model.expression
    if (record && record.startsWith('records.')) {
      record = record.substr(8)
    } else if (binding.arg == 'di') {
      record = vnode.componentInstance.di.name
    }
    if (!vnode.context.routeChanging && !vnode.context.questionnaireSaving) {
      if (vnode.data.model) vnode.data.model.callback()
      // vnode.context.$store.dispatch('questionnaires/updateRecord',{ field: record, value: null })
    }
  }
})
Vue.filter('humanDate', (dateStr) => {
  var date = moment(dateStr)
  var dateDiff = date.diff(moment())
  return moment.duration(dateDiff).humanize(true)
})
Vue.filter('dmy', (value) => {
  if (value)
    return dateMixin.methods.formatDateForDisplay(value);
})
Vue.filter('age', (value) => {
  if (value) {
    var date = moment(value, 'YYYY-MM-DD');
    if (date.isValid()) return moment().diff(date, 'years');
  }
})


Vue.directive('clear', {
  unbind: function (el, binding, vnode) {
    // Find the elements record and clear it
    // var record = binding && binding.value ? binding.value : false
    // if(vnode.data.model && vnode.data.model.expression) record = vnode.data.model.expression
    // if (record && record.startsWith('records.')) {
    //   record = record.substr(8)
    // } else if (binding.arg == 'di') {
    //   record = vnode.componentInstance.di.name
    // }
    // console.log('unbind', record, vnode.data.model) 
    if (!vnode.context.routeChanging && !vnode.context.isSaving) {
      var pd = vnode.componentOptions.propsData
      if (pd.id) {
        vnode.context.$store.dispatch('editor/updateRecord', { field: pd.id, value: null })
      }
      if (pd.di) {
        vnode.context.$store.dispatch('editor/updateRecord', { field: pd.di.name, value: null })
      }
      // if(vnode.data.model) vnode.data.model.callback()
      //   // vnode.context.$store.dispatch('editor/updateRecord',{ field: record, value: null })
    }
  }
})

Vue.directive('resize', {
  inserted(el) {

    el.style.height = el.scrollHeight + 'px';
  },
  update(el) {
    el.style.height = 0;
    el.style.height = el.scrollHeight + 'px';
  }
})
Vue.directive('focus', {
  inserted(el) {
    el.focus();
  }
})



Vue.directive('popover', {
  inserted: function (el, binding) {
    if (binding.value && binding.value.disabled || !el) {
      return false
    }
    var popoverClass = Vue.extend(popoverComponent)
    var componentProps = { propsData: { target: el, ...binding.value } }
    var instance = new popoverClass(componentProps)
    instance.$mount();
    el.parentNode.appendChild(instance.$el);
    el.popoverEl = instance.$el;

  },
  componentUpdated: function (el, binding) {
    if (binding.value && binding.value.disabled) {
      var myobj = el.parentNode.querySelector(".popover");
      if (el.popoverEl) el.popoverEl.remove()
      if (myobj) myobj.remove();
      return false
    } else if (!binding.value.disabled) {

      // var popoverClass = Vue.extend(popoverComponent)
      // var componentProps = { propsData: { target: el, ...binding.value } }
      // var instance = new popoverClass(componentProps)
      // instance.$mount();
      // el.parentNode.appendChild(instance.$el);
    }

  },
  unbind: function (el) {
    if (el.parentNode) {

      var myobj = el.parentNode.querySelector(".popover");
      if (myobj) myobj.remove();
    } else {
      // var myobj = document.querySelectorAll(".popover")
      // if (myobj.length) myobj.forEach((po) => {
      //   if (po) po.remove()
      // })
    }

  }
})



Vue.prototype.$filters = Vue.options.filters